/* You can add global styles to this file, and also import other style files */
.banner-section-para{
    position: absolute;
    bottom: 0% !important;
    left: 0%;
    right: 0%;
    opacity: 0.9;
}
li.list-group-item {
    // background: #8b5d17;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #8b5d17;
    // color: #fff;
    font-size: 14px;
    transition: background-color 0.3s ease; /* Define transition property */
}

.banner-section.toppagesbannerimg img.img-fluid{
  margin-top: -7%;
}

/* Apply animation on hover */
li.list-group-item:hover {
    // background-color: #654312; /* Change background color on hover */
    animation: move 0.5s infinite; /* Apply the 'move' animation */

}

@keyframes move {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px); /* Move 5px to the right */
    }
    100% {
      transform: translateX(0);
    }
  }
  about-page p {
    line-height: 2;
    color: black;
    letter-spacing: 0.03em;
    font-weight: 400;
}
.toppagesbannertitle{
font-size: 3em; text-transform: capitalize; color: #ffffff;  padding: 5px;
text-align: center; background: #926b21; font-weight: bold;
}

.wroklife-content-box {
  text-align: center;
  padding: 10%;
  border: 1px solid #e4ceaa;
  margin: 2%;
}

.banner-section.toppagesbannerimg{
    position: relative;
     height: 40%;
}

.col-sm-12 h2 {
  font-size: 24px;
}

@media screen and (min-width: 700px) {
    .banner-section.toppagesbannerimg{
        position: relative;
         height: 430px !important;
    }
    .forportfoliopage{
        background: black;
        height: 500px;
    }
  //   .ch-box {
  //     height: auto;
  // }
  }

  .brand-logo a img {
    width: 150px;
}
.footer-logo img {
  width: 150px;
}
  
@media (max-width: 577px) {
  .mobile-fix-option {
      display: none;
  }
  footer {
    margin-bottom: 1px;
}
.col-lg-4 .ourvalue-content-box {
  text-align: center;
}
}

header .main-navbar .nav-menu > li .nav-submenu li a .sub-arrow-minus {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 15px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 16px / 34px monospace !important;
  text-align: center;
  text-shadow: none;
  border-radius: 4px;
}

span.sub-arrow-minus {
  position: relative;
  left: 184px;
  font-size: 20px;
  text-transform: uppercase;
}
p.dis_success {
  background: #d4ba67;
  color: green;
  padding: 1%;
  margin: 1%;
}


@media (max-width: 1199px) {
  header .main-navbar .nav-menu > li a .sub-arrow-minus:before {
      content: "-";
      font-family: inherit;
      position: relative;
  }
}
  @media screen and (max-width: 900px) {
    .ch-box {
      height: auto;
  }
  .brand-logo a img {
    // width: auto; 
}
  .footer-social, .social-white {
    margin-top: 40px;
    display: flex;
    align-items: center;
}
.footer-theme2.section-light .footer-block {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
  .home-slider .slider-contain .btn-solid, .home-slider .slider-contain .btn-outline {
        text-align: center;
        margin-top: 5px;
        width: 33%;
        margin-right: 33%;
        margin-left: 33%;
    }
   
        .footer-logo{
         text-align: center !important;
    }
    .footer-contant {
    text-align: center;
}
ul.contact-details li {
    text-align: center;
    
}
ul.contact-details  {
    padding: 12px;
    
}
.social-white ul {
    text-align: center; 
}
  }
  
  ul.contact-details li {
    text-align: center !important;
}
section, .section-t-space {
    padding-top: 5px !important;
}
.forportfoliopage{
    background: black;
}
.absolut-container h3 {
  color: #fff;
}
.paragraph-wrapper p {
  color: #fff;
}
.footer-theme2.section-light .footer-block .subscribe-white:after {
  content: "";
  background-color: #c9ab58 !important;
}
.btn-solid {
  border: 2px solid #c9ab58 !important;
  background-image: linear-gradient(30deg, #c9ab58 50%, transparent 50%) !important;
 
}
.toggle-nav i {
  font-size: 24px;
  color: #c9ab58;
}
.ch-box {
  opacity: 0.9;
}
.sub-footer.black-subfooter p {
  color: #fff;
}
.same-heading-ewallet.text-center {
  margin: 5% 0px 5% 0px;
}
.valbox {
  margin: 10% 0px 18% 0;
  text-align: center;
}

.wroklife-content-box {
  text-align: center;
  padding: 10%;
  border: 1px solid #e4ceaa;
  margin: 2%;
  max-height: 440px;
  height: 440px;
}
section.about-page.section-b-space h2 {
  margin: 2% 0 2% 0;
}
section.about-page.section-b-space h3 {
  margin: 2% 0 2% 0;
}
section.about-page.section-b-space ul {
  margin: 1% 0 1% 0;
}
section.about-page.section-b-space ul li{
  margin: 1% 0 1% 0;
}
section.about-page.section-b-space 
h1, .h1 {
  font-size: 2.5rem;
}
.btn-solid {
  border: 2px solid #c9ab58 !important;
  background-image: linear-gradient(30deg, #c9ab58 50%, transparent 50%) !important;
  color: black !important;
}